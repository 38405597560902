<template>
    <div class="modal fade" :class="{ 'show': showModal }" id="exampleModalLive" tabindex="-1" aria-labelledby="exampleModalLiveLabel" aria-modal="true" role="dialog">
    <div class="modal-dialog modal-dialog-centered" style="min-width: 400px">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title text-center text-dark" id="exampleModalLiveLabel">{{ title }}</h5>
        <button type="button" @click="hide" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
        <slot></slot>
      </div>
      
    </div>
  </div>
</div>
</template>

<script>
    export default {
        props: {
            showModal: {
                type: Boolean,
                required: true,
            },
            title: {
                type: String,
                required: true,
            }
        },
        // data(){
        //     return {
        //         showModal: true,
        //     }
        // },
        // computed: {
        //     modalStatusClass(){
        //         return {
        //              display: this.show ? 'block' : 'hidden', 
        //         }
        //     }
        // },
        methods: {
            hide(){
                this.$eventBus.$emit('hideModal');
            }
        }
    }
</script>

<style scoped>

    #exampleModalLive{
        background: #676262b8;
         min-height: calc(100vh - 60px);
        display: flex;
        flex-direction: column;
        justify-content: center;
        overflow: auto;
    }
   
@media(max-width: 768px) {
  .exampleModalLive {
    min-height: calc(100vh - 20px);
  }
}


</style>